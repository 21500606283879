import TextLinkV2 from "components/common/TextLinkV2";
import { toTitleCase } from "components/common/util/helperFunctions";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
  Title,
} from "components/rhombus-UI/theme/typography";
import { useIsVisible } from "hooks/useIsVisible";
import { styled } from "linaria/react";
import React, { useCallback, useRef } from "react";

import Frame1 from "./animation-frames/Frame1";
import Frame2 from "./animation-frames/Frame2";
import Frame3 from "./animation-frames/Frame3";
import Frame4 from "./animation-frames/Frame4";
import Frame5 from "./animation-frames/Frame5";

const AnimatedScrollSectionContainer = styled(SectionContainer)`
  overflow: unset;
`;

const ScrollSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  gap: 2rem;
`;

const TextColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 10rem;
`;

const TextSection = styled.div`
  padding: 0 0;
  height: 500px;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0.5rem;
  }
  @media (min-width: 1800px) {
    max-height: unset;
  }
`;

const StickySection = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  width: 800px;
  flex-shrink: 0;
  position: sticky;
  position: -webkit-sticky;
  top: calc((100dvh - 5rem) / 4);
  @media (min-width: 1800px) {
    max-height: unset;
  }
`;

const ResponsiveTitle = styled(Title)`
  color: var(--blue-500);
  font-size: 32px;
`;

const ScrollSectionTitle = styled(FancyTitle)`
  text-align: center;
`;

const AnimatedScrollSection = () => {
  const frameRef1 = useRef();
  const frameRef2 = useRef();
  const frameRef3 = useRef();
  const frameRef4 = useRef();

  const frame1Visible = useIsVisible(frameRef1);
  const frame2Visible = useIsVisible(frameRef2);
  const frame3Visible = useIsVisible(frameRef3);
  const frame4Visible = useIsVisible(frameRef4);

  const handleFrameRender = useCallback(() => {
    if (frame1Visible) {
      return <Frame1 />;
    }
    if (frame2Visible) {
      return <Frame2 />;
    }
    if (frame3Visible) {
      return <Frame3 />;
    }
    if (frame4Visible) {
      return <Frame4 />;
    }
    return <Frame5 />;
  }, [frame1Visible, frame2Visible, frame3Visible, frame4Visible]);

  return (
    <AnimatedScrollSectionContainer>
      <SectionInner
        style={{
          flexDirection: "column",
        }}
      >
        <ScrollSectionTitle>
          Modern Physical Security Made Easy
        </ScrollSectionTitle>
        <ScrollSection>
          <StickySection>{handleFrameRender()}</StickySection>
          <TextColumn>
            <TextSection>
              <TextContainer width="500px">
                <PreTitle ref={frameRef1}>SEAMLESS DEPLOYMENT</PreTitle>
                <ResponsiveTitle>
                  {toTitleCase(
                    "Register Plug-and-Play Devices in as Little as 5 Minutes"
                  )}
                </ResponsiveTitle>
                <MainParagraph>
                  Complex NVR/DVRs are a thing of the past. Rhombus’ modern
                  architecture ensures fast and easy deployment from anywhere.
                  With simplified hardware infrastructure, installation is
                  seamless with a single cable connection via PoE.
                </MainParagraph>
                <TextLinkV2
                  path="/lp/traditional-vs-cloud/"
                  title="Learn More"
                />
              </TextContainer>
            </TextSection>
            <TextSection>
              <TextContainer width="500px">
                <PreTitle ref={frameRef2}>BUILT FOR SCALABILITY</PreTitle>
                <ResponsiveTitle>
                  {toTitleCase(
                    " Enjoy an all-in-one solution that grows with your needs"
                  )}
                </ResponsiveTitle>
                <MainParagraph>
                  With a broad suite of products that is constantly growing,
                  Rhombus provides a true single-pane-of-glass experience.
                  Remotely manage cameras, sensors, access control, alarms, and
                  integrations from a centralized platform.
                </MainParagraph>
                <TextLinkV2 path="/why-rhombus/" title="Learn More" />
              </TextContainer>
            </TextSection>
            <TextSection>
              <TextContainer width="500px">
                <PreTitle ref={frameRef3}>SIMPLIFIED MANAGEMENT</PreTitle>
                <ResponsiveTitle>
                  {toTitleCase(
                    "Save time with multi-camera investigations and smart search"
                  )}
                </ResponsiveTitle>
                <MainParagraph>
                  No more manual scrubbing. Camera and sensor data are layered
                  together for fast investigations. Quickly search past footage,
                  search for events, and securely share clips via email, text,
                  or URL all from the Rhombus timeline.
                </MainParagraph>
                <TextLinkV2 path="/console/" title="Learn More" />
              </TextContainer>
            </TextSection>
            <TextSection>
              <TextContainer width="500px">
                <PreTitle ref={frameRef4}>ADVANCED ANALYTICS</PreTitle>
                <ResponsiveTitle>
                  {toTitleCase(
                    "Get real-time Notifications for faces, vehicles, and more"
                  )}
                </ResponsiveTitle>
                <MainParagraph>
                  Save time, accelerate investigations, and receive real-time
                  intelligent alerts with AI Analytics. Machine-learning helps
                  your system determine what ‘normal’ looks like in your spaces
                  and becomes smarter over time.
                </MainParagraph>
                <TextLinkV2 path="/ai-analytics/" title="Learn More" />
              </TextContainer>
            </TextSection>
            <TextSection>
              <TextContainer width="500px">
                <PreTitle>INTEGRATIONS</PreTitle>
                <ResponsiveTitle>
                  {toTitleCase(
                    "Customize Your System with Industry-Leading Solutions"
                  )}
                </ResponsiveTitle>
                <MainParagraph>
                  Streamline operations by unifying video with your existing
                  tech stack and favorite third-party systems. Get plug-and-play
                  integrations for deeper insights into POS events, guest
                  management, operational data, and more.
                </MainParagraph>
                <TextLinkV2 path="/integrations/" title="Learn More" />
              </TextContainer>
            </TextSection>
          </TextColumn>
        </ScrollSection>
      </SectionInner>
    </AnimatedScrollSectionContainer>
  );
};

export default AnimatedScrollSection;
