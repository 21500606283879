import HomePageV2 from "components/home/HomePageV2";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function Home() {
  const color = "transparent";
  return (
    <GlobalLayout transparent color={color} dark>
      <Helmet>
        <title>
          Cloud Physical Security Solutions | Commercial Security System |
          Rhombus
        </title>
        <meta
          name="description"
          content="Rhombus offers physical security solutions in a single cloud-managed platform. A commercial security system to improve operations and deter threats. Request a demo today. "
        />
      </Helmet>
      <HomePageV2 />
    </GlobalLayout>
  );
}

export const fragmentsQuery = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
