import { styled } from "linaria/react";
import React, { useRef } from "react";

import LogoSlider from "../common/LogoSlider";
import RhombusButton from "../common/RhombusButton";
import { SectionContainer } from "../rhombus-UI/theme/containers";
import { MainParagraph, PageTitle } from "../rhombus-UI/theme/typography";
import { Helmet } from "react-helmet";
import { useIsVisible } from "hooks/useIsVisible";

const HeroVideoContainer = styled(SectionContainer)`
  padding: 0;
  margin-top: -80px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
  overflow: hidden;
`;

const VideoOverlay = styled.div`
  background: var(--nuetral-900);
  height: calc(100vh);
  position: relative;
`;

const BackgroundVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  filter: opacity(0.6);
`;

const VideoText = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  gap: 1.25rem;
  color: var(--nuetral-100);
  padding: 0 1.25rem;
`;

const HeroVideo = () => {
  const videoUrl =
    "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/home-hero-video-trimmed.webm";
  const videoRef = useRef(null);
  const isVisible = useIsVisible(videoRef);

  return (
    <HeroVideoContainer>
      <Helmet>
        <link rel="preload" href={videoUrl} as="video" type="video/webm" />
      </Helmet>
      <VideoOverlay ref={videoRef}>
        <VideoText>
          <PageTitle>Smarter, Safer Spaces</PageTitle>
          <MainParagraph>
            Physical security made modern with a unified, cloud-based system.
          </MainParagraph>
          <RhombusButton
            type="gradient"
            title="Request Demo"
            path="/live-demo/"
          />
        </VideoText>
        {isVisible && (
          <BackgroundVideo autoPlay loop muted playsInline>
            <source src={videoUrl} type="video/webm" />
          </BackgroundVideo>
        )}
      </VideoOverlay>
      <LogoSlider />
    </HeroVideoContainer>
  );
};

export default HeroVideo;
