import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";
import { animated, useSpring } from "@react-spring/web";
import Bubble1 from "./img/frame-5/toast.png";
import Bubble2 from "./img/frame-5/drop-box.png";
import Bubble3 from "./img/frame-5/lumeo.png";
import { frameContainer } from "./styles/frameStyles";

const bubble1 = css`
  position: absolute;
  bottom: 9rem;
  width: 280px;
`;

const bubble2 = css`
  position: absolute;
  bottom: 9.5rem;
  margin: auto;
  width: 280px;
`;
const bubble3 = css`
  position: absolute;
  left: 0;
  bottom: unset;
  width: 280px;
`;

const Frame5 = () => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  const [popInLeft] = useSpring(
    () => ({
      from: { opacity: 0, left: "-5rem " },
      to: { opacity: 1, left: "6.75rem" },
    }),
    []
  );
  const [popInRight] = useSpring(
    () => ({
      from: { opacity: 0, right: "-5rem " },
      to: { opacity: 1, right: "4rem" },
      delay: 500,
    }),
    []
  );

  const [popInTop] = useSpring(
    () => ({
      from: { opacity: 0, top: "-5rem " },
      to: { opacity: 1, top: "8rem" },
      delay: 1000,
    }),
    []
  );

  return (
    <animated.div className={frameContainer} style={fadeIn}>
      <StaticImage src="./img/frame-5/frame-img-min.png" alt="" />
      <animated.img src={Bubble1} className={bubble1} style={popInLeft} />
      <animated.img src={Bubble2} className={bubble2} style={popInRight} />
      <animated.img src={Bubble3} className={bubble3} style={popInTop} />
    </animated.div>
  );
};

export default Frame5;
