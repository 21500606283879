import DeviceCards from "components/common/DeviceCards";
import CTABanner from "components/common/footer-banners/CTABanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";

import AnimatedScrollSection from "./AnimatedScrollSection";
import AnimatedScrollSectionMobile from "./AnimatedScrollSectionMobile";
import G2Section from "./G2Section";
import HeroVideo from "./HeroVideo";

export default function HomePageV2() {
  const { isMobile } = useIsMobile(1200);
  const renderContent = data => {
    const productCards = {
      title: "Transform How You Secure Your Spaces",
      cards: [
        {
          image: data.product1,
          name: "Cameras",
          description:
            "Powerful onboard analytics and real-time processing for comprehensive visibility",
          link: "/cameras/",
          flex: 4,
        },
        {
          image: data.product2,
          name: "Access Control",
          description:
            "Remotely manage building access and credentials from anywhere ",
          link: "/access-control/",
          flex: 4,
        },
        {
          image: data.product3,
          name: "Sensors",
          description:
            "Unlock real-time, actionable insights with a diverse suite of IoT smart sensors",
          link: "/sensors/",
          flex: 4,
        },
        {
          image: data.product4,
          name: "Alarm Monitoring",
          description:
            "Certified agents monitor your spaces for faster, verified emergency response",
          link: "/alarm-monitoring/",
          flex: 4,
        },
      ],
    };

    return (
      <>
        {/* <HeroLayout4
          title="Create Smarter, Safer Spaces on a Unified Cloud Platform"
          blurb="Physical security made modern. Manage your buildings anytime, anywhere with an all-in-one solution that brings smart cameras, access control, sensors, integrations, and alarms together under a single pane of glass."
          mobileBlurb="Manage your buildings anytime, anywhere with an all-in-one solution including smart cameras, access control, sensors, integrations, and alarms."
          buttonTitle="Request Demo"
          buttonPath="/live-demo/"
          image={data.hero}
          mobileImage={data.mobileHero}
          dark={dark}
          color={color}
        /> */}
        <HeroVideo />
        <DeviceCards
          data={productCards}
          color="var(--nuetral-100)"
          center
          use4Cards
        />
        {isMobile ? <AnimatedScrollSectionMobile /> : <AnimatedScrollSection />}
        <G2Section />
        <IndustryLeadersCards />
        <CTABanner
          title="Try Rhombus for Yourself"
          blurb="Request a free trial with our team today and see why industry leaders choose Rhombus as their trusted security solution. "
        />
      </>
    );
  };

  const query = graphql`
    query {
      hero: file(
        relativePath: { eq: "components/home/img/home-hero-new-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      mobileHero: file(
        relativePath: { eq: "components/home/img/mobile-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      product1: file(
        relativePath: { eq: "components/home/img/cameras-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      product2: file(
        relativePath: { eq: "components/home/img/access-control-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      product3: file(
        relativePath: { eq: "components/home/img/sensors-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      product4: file(
        relativePath: { eq: "components/home/img/alarm-mon-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
