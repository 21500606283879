import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { css } from "linaria";
import Notification from "./img/frame4/notification.png";
import { frameContainer } from "./styles/frameStyles";

const notificationBubble = css`
  position: absolute;
  margin: auto;
  right: 7rem;
  z-index: 2;
  width: 250px;
`;

const Frame4 = () => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  const [popInLeft] = useSpring(
    () => ({
      from: { opacity: 0, bottom: "-5rem " },
      to: { opacity: 1, bottom: "9.5rem" },
    }),
    []
  );

  return (
    <animated.div className={frameContainer} style={fadeIn}>
      <StaticImage
        src="./img/frame4/frame-4-image.png"
        alt=""
        objectFit="contain"
      />
      <animated.img
        src={Notification}
        style={popInLeft}
        className={notificationBubble}
      />
    </animated.div>
  );
};

export default Frame4;
