import { StaticImage } from "gatsby-plugin-image";
import { css, cx } from "linaria";
import React from "react";
import { animated, useSpring } from "@react-spring/web";
import Bubble1 from "./img/frame2/credentials.png";
import Bubble2 from "./img/frame2/facial-recognition.png";
import Bubble3 from "./img/frame2/a100.png";
import { frameContainer } from "./styles/frameStyles";

const bubble1 = css`
  position: absolute;
  bottom: 7rem;
  width: 280px;
`;

const bubble2 = css`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  margin: auto;
  width: 280px;
`;
const bubble3 = css`
  position: absolute;
  left: 7rem;
  bottom: unset;
  width: 280px;
`;

const Frame2 = () => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  const [popInLeft] = useSpring(
    () => ({
      from: { opacity: 0, left: "-5rem " },
      to: { opacity: 1, left: "3rem" },
    }),
    []
  );
  const [popInRight] = useSpring(
    () => ({
      from: { opacity: 0, right: "-5rem " },
      to: { opacity: 1, right: "0" },
      delay: 500,
    }),
    []
  );

  const [popInTop] = useSpring(
    () => ({
      from: { opacity: 0, top: "-5rem " },
      to: { opacity: 1, top: "3rem" },
      delay: 1000,
    }),
    []
  );

  return (
    <animated.div className={frameContainer} style={fadeIn}>
      <StaticImage src="./img/frame2/frame-2-image-min.png" alt="" />
      <animated.img src={Bubble1} className={bubble1} style={popInLeft} />
      <animated.img src={Bubble2} className={bubble2} style={popInRight} />
      <animated.img src={Bubble3} className={bubble3} style={popInTop} />
    </animated.div>
  );
};

export default Frame2;
