import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { css } from "linaria";
import Notification from "./img/frame1/notification.png";
import { frameContainer } from "./styles/frameStyles";

const notificationBubble = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  width: 280px;
`;

const Frame1 = () => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  const [popInLeft] = useSpring(
    () => ({
      from: { opacity: 0, left: "-5rem " },
      to: { opacity: 1, left: "-2rem" },
      delay: 1000,
    }),
    []
  );

  return (
    <animated.div className={frameContainer} style={fadeIn}>
      <StaticImage
        src="./img/frame1/frame-1-image-min.png"
        alt=""
        objectFit="contain"
      />
      <animated.img
        src={Notification}
        style={popInLeft}
        className={notificationBubble}
      />
    </animated.div>
  );
};

export default Frame1;
