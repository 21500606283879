import TextLinkV2 from "components/common/TextLinkV2";
import { toTitleCase } from "components/common/util/helperFunctions";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { styled } from "linaria/react";
import React from "react";

const TextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function AnimatedScrollSectionMobile() {
  const renderContent = data => {
    const TEXT_SECTIONS = [
      {
        preTitle: "SEAMLESS DEPLOYMENT",
        title: "Register Plug-and-Play Devices in as Little as 5 Minutes",
        blurb:
          "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE.",
        image: data.image1,
        path: "/lp/traditional-vs-cloud/",
      },
      {
        preTitle: "BUILT FOR SCALABILITY",
        title: "Enjoy an all-in-one solution that grows with your needs",
        blurb:
          "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage cameras, sensors, access control, alarms, and integrations from a centralized platform.",
        image: data.image2,
        path: "/why-rhombus/",
      },
      {
        preTitle: "SIMPLIFIED MANAGEMENT",
        title: "Save time with multi-camera investigations and smart search",
        blurb:
          "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
        image: data.image3,
        path: "/console/",
      },
      {
        preTitle: "ADVANCED ANALYTICS",
        title: "Get real-time Notifications for faces, vehicles, and more",
        blurb:
          "Save time, accelerate investigations, and receive real-time intelligent alerts with AI Analytics. Machine-learning helps your system determine what ‘normal’ looks like in your spaces and becomes smarter over time.",
        image: data.image4,
        path: "/ai-analytics/",
      },
      {
        preTitle: "INTEGRATIONS",
        title: "Customize Your System with Industry-Leading Solutions",
        blurb:
          "Streamline operations by unifying video with your existing tech stack and favorite third-party systems. Get plug-and-play integrations for deeper insights into POS events, guest management, operational data, and more",
        image: data.image5,
        path: "/integrations/",
      },
    ];

    return (
      <SectionContainer>
        <SectionInner
          style={{ flexDirection: "column", alignItems: "center", gap: "5rem" }}
        >
          <FancyTitle style={{ textAlign: "center" }}>
            Modern Physical Security Made Easy
          </FancyTitle>
          {TEXT_SECTIONS.map(item => (
            <TextBox>
              <TextContainer width="600px">
                <GatsbyImage
                  image={getImage(item.image)}
                  alt=""
                  style={{ marginBottom: "2.5rem" }}
                />
                {item.preTitle && <PreTitle>{item.preTitle}</PreTitle>}
                <TitleMed color="var(--blue-500)">
                  {toTitleCase(item.title)}
                </TitleMed>
                <MainParagraph>{item.blurb}</MainParagraph>
                <TextLinkV2 path={item.path} title="Learn More" />
              </TextContainer>
            </TextBox>
          ))}
        </SectionInner>
      </SectionContainer>
    );
  };

  const IMAGE_QUERY = graphql`
    query {
      image1: file(
        relativePath: { eq: "components/home/img/frame-1-static-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      image2: file(
        relativePath: { eq: "components/home/img/frame-2-static-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      image3: file(
        relativePath: { eq: "components/home/img/frame-3-static-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      image4: file(
        relativePath: { eq: "components/home/img/frame-4-static-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      image5: file(
        relativePath: {
          eq: "components/home/img/integrations-dashboard-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={IMAGE_QUERY} render={renderContent} />;
}
