import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { frameContainer } from "./styles/frameStyles";
import { animated, useSpring } from "@react-spring/web";
import Bubble1 from "./img/frame3/notification.png";
import Bubble2Var from "./img/frame3/classroom-b12-variant.png";
import Bubble3 from "./img/frame3/classroom-a14.png";
import { css } from "linaria";

const bubble1 = css`
  position: absolute;
  bottom: 12rem;
  width: 280px;
`;

const bubble2 = css`
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 280px;
`;

const bubble3 = css`
  position: absolute;
  right: 0;
  bottom: unset;
  width: 280px;
`;

const Frame3 = () => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  const [popInLeft] = useSpring(
    () => ({
      from: { opacity: 0, left: "-5rem " },
      to: { opacity: 1, left: "5rem" },
    }),
    []
  );
  const [popInRight] = useSpring(
    () => ({
      from: { opacity: 0, right: "-5rem " },
      to: { opacity: 1, right: "0" },
      delay: 500,
    }),
    []
  );

  const [popInTop] = useSpring(
    () => ({
      from: { opacity: 0, top: "-5rem " },
      to: { opacity: 1, top: "3rem" },
      delay: 1000,
    }),
    []
  );

  return (
    <animated.div className={frameContainer} style={fadeIn}>
      <StaticImage src="./img/frame3/frame-3-image-min.png" alt="" />
      <animated.img src={Bubble1} className={bubble1} style={popInLeft} />
      <animated.img src={Bubble2Var} className={bubble2} style={popInRight} />
      <animated.img src={Bubble3} className={bubble3} style={popInTop} />
    </animated.div>
  );
};

export default Frame3;
